import { Text } from '@mantine/core';
import { modals } from '@mantine/modals';
import { OpenConfirmModal } from '@mantine/modals/lib/context';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import React from 'react';

export const wentWrong = (message: string, title = 'Something went wrong') =>
  notifications.show({
    title,
    message,
    color: 'red',
    icon: <IconX />,
  });

export const wentRight = (message: string, title: string) =>
  notifications.show({
    title,
    message,
    color: 'green',
    icon: <IconCheck />,
  });

type ConfirmModalProps = OpenConfirmModal & {
  message: string;
  confirm?: string;
  cancel?: string;
};

export const askToConfirm = ({
  title,
  message,
  confirm = 'Confirm',
  cancel = 'Cancel',
  onConfirm,
  onCancel,
  onClose,
  ...props
}: ConfirmModalProps) =>
  modals.openConfirmModal({
    title,
    centered: true,
    children: <Text size="sm">{message}</Text>,
    labels: { confirm, cancel },
    confirmProps: { color: 'red' },
    // we can have multiple entrypoint on the same page, so provider will create multiple modals
    // we need to close all of them, but the action will trigger only once
    onConfirm: () => {
      modals.closeAll();
      return onConfirm?.();
    },
    onCancel: () => {
      modals.closeAll();
      return onCancel?.();
    },
    onClose: () => {
      modals.closeAll();
      return onClose?.();
    },
    ...props,
  });
